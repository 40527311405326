import React from "react";
import styled from "styled-components";
import { ClosebtnX } from "../common/styleComponent";
import Card from "../components/Card";

const Payment = ({
  setPaymentModal,
  data,
  email,
  handleBookingApi,
  values,
}) => {

  return (
    <BookConfirmedStyle>
      <ClosebtnX onClick={() => setPaymentModal(false)}>x</ClosebtnX>
      <div className="modal-container">
        <h2 style={{ textAlign: "center" }}> Complete your Payment</h2>
        {data && email && (
          <Card
            data={data}
            email={email}
            handleBookingApi={handleBookingApi}
            values={values}
            setPaymentModal={setPaymentModal}
          />
        )}
      </div>
    </BookConfirmedStyle>
  );
};

export default Payment;

const BookConfirmedStyle = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  /* background-color: rgb(51, 55, 78); */
  z-index: 9999999;
  width: 100%;
  height: 100vh;

  .modal-container {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 450px;
    gap: 20px;
    transform: translate(-50%, -50%);
    background-color: #f7f6f4;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 32px;
    padding: 52px;
    animation-name: set-animation;
    animation-duration: 0.7s;
    .AnimatePresence {
      display: none !important;
    }
    @media (max-width: 600px) {
      width:85%;
      padding:20px;
    }

  }
`;
