import React, { memo, useState } from "react";
import { Field } from "formik";
import styled from "styled-components";
import { IoMdEyeOff, IoIosEye } from "react-icons/io";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { IoSend } from "react-icons/io5";
import ButtonLoading from "../components/ButtonLoader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function InputFields({
  field,
  errors,
  touched,
  onChange,
  verifyEmail,
  verifyEmailLoading,
  setPhoneData,
}) {
  const [eyesbtn, setEyesbtn] = useState(false);
  const [sendEmailbtn, setEmailbtn] = useState(false);
  const [emailValue, setEmailValue] = useState("");

  const emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  let type = field?.type;
  if (type === "password") {
    if (eyesbtn === true) {
      type = "text";
    }
  }

  let className = field?.className;
  const numberLength = (e) => {
    if (field?.phoneno) {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }
    if (field?.type === "email") {
      if (emailRegex.test(e.target.value)) {
        setEmailValue(e.target.value);
        setEmailbtn(true);
      } else {
        setEmailbtn(false);
      }
    }
    if (field?.name === "firstName" || field?.name === "childFirstName") {
      e.target.value = e.target.value.replace(/ /g, "");
    }
  };
  const sendMessage = () => {
    onChange(emailValue);
  };

  const handlePhoneChange = (value, data) => {
    const countryCode = data.dialCode;
    const phoneNumber = value.startsWith(`${countryCode}`)
      ? value.slice(countryCode.length) // Remove '+countryCode'
      : value; // Remove country code from the number

    setPhoneData({
      countryCode: `+${countryCode}`,
      phoneNumber: phoneNumber,
    });
  };

  return (
    <InputContainer width={field?.width} height={field?.height}>
      {field?.label && <label className="label">{field?.label}</label>}

      <div className={className ? className : "icon-input-box"}>
        {field?.phoneno && (
          <PhoneInput
            country={"au"}
            onChange={handlePhoneChange}
            enableSearch={true}
            disableSearchIcon={true}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
              enableSearch: true,
            }}
          />
        )}
        {!field?.phoneno && (
          <Field
            className="input-field"
            name={field?.name}
            type={field?.type}
            placeholder={field?.placeholder}
            maxLength={field?.maxlength}
            onInput={numberLength}
          />
        )}
        {field?.eyes && (
          <span onClick={() => setEyesbtn(!eyesbtn)} role="button">
            {eyesbtn ? (
              <IoIosEye className="eyes-icon" />
            ) : (
              <IoMdEyeOff className="eyes-icon" />
            )}
          </span>
        )}
        {sendEmailbtn && (
          <span role="button">
            {verifyEmail ? (
              <MdOutlineVerifiedUser className="email-verify" />
            ) : verifyEmailLoading ? (
              <ButtonLoading width="30px" onlyLoading={true} />
            ) : (
              <IoSend
                onClick={sendMessage}
                className="email-icon"
                title={`To confirm your identity, we’ll send
 a one-time password (OTP) to your email.`}
              />
            )}
          </span>
        )}
      </div>
      {}

      <p className="input-error">
        {errors[field.name] && touched[field.name] && errors[field.name]}
      </p>
    </InputContainer>
  );
}
export default memo(InputFields);

const InputContainer = styled.div`
  width: ${(props) => props.width || "100%"};
  display: flex;
  flex-direction: column;
  .form-control {
    border: none;
    width: 100%;
    background: transparent;
  }

  .input-field {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    font-weight: 600;
    font-family: Montserrat;
  }
  .label {
    width: 100%;
  }
  .icon-input-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #2e3038;
    padding: 8px 16px;
    height: ${(props) => props.height || "56px"};
    outline: none;
  }

  .eyes-icon {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .email-icon {
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .email-verify {
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: green;
  }
  .verify-otp-container {
    display: flex;
    justify-content: end;
    padding: 5px 0;
  }
  input.verify-input {
    width: 100px;
    height: 30px;
    font-weight: 900;
    padding: 5px;
    border: 1px solid #2e3038;
    border-radius: 5px;
  }
  .input-error {
    width: 100%;
    color: red;
    font-weight: 500;
    font-size: 13px;
    height: 23px;
    margin: 0;
    padding: 0;
  }
  .selecter-box {
    width: 111px;
    height: 40px;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px #969bab solid;
  }
  .select-phone {
    width: 100%;
    height: 100%;
    border: none;
    background: no-repeat;
    outline: none;
    font-size: 20px;
    cursor: pointer;
  }
  option.option-box {
    font-size: 20px;
    color: black;
    font-weight: 500;
    margin: 10px 0;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* .select{
  width: 10%;
    height: 50px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-left: 5px;
    padding: 10px;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    color: #555;
    background-color: rgb(255, 255, 255);
    background-image: none;
    border: 1px solid rgb(41, 18, 18);
}
select>option{
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    color: #555;
    background-color: rgb(247, 247, 247);
    background-image: none;
    font-size: 18px;
    height: 50px;
    padding: 15px;
    border: 1px solid rgb(41, 18, 18);
} */
`;
