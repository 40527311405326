import React, { useState } from "react";
import styled from "styled-components";
import shareandroid from "../assets/share-android.svg";
import fblogo from "../assets/facebook.svg";
import linkdinlogo from "../assets/linkedin.svg";
import grouplogo from "../assets/Group 1597882914.svg";
import telegramlogo from "../assets/telegram.svg";
import patternupperimage from "../assets/Pattern13.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IoMdCopy } from "react-icons/io";
import {bydefimageOutcoach} from "../utils/imageSvg"
import { toast } from "react-toastify";

function Header() {
  const clubDetails = useSelector((state) => state?.login?.data);
  const location = useLocation()?.pathname;
  const token = useSelector((store) => store?.login?.data?.token);
  const navigate = useNavigate();
  const imageBaseURL = process.env.REACT_APP_IMAGE_BASEURL;
  const reactAppURL = process.env.REACT_APP_FRONTEND_BASEURL;
  const shareLink = `${reactAppURL}${token}`;
  let logo = clubDetails?.clubLogo;
  const [open,setOpen] = useState(false)

  const socialmedialink = [
    {
      id: "1",
      logo: fblogo,
      link: `https://www.facebook.com/sharer/sharer?u=${shareLink}`,
    },
    {
      id: "2",
      logo: linkdinlogo,
      link: `https://www.linkedin.com/sharing/share-offsite/?url=${shareLink}`,
    },
    {
      id: "4",
      logo: grouplogo,
      link: `https://www.instagram.com/?url=${shareLink}`,
    },
    {
      id: "5",
      logo: telegramlogo,
      link: `https://telegram.me/share/url?url=${shareLink}&text=OutCoach`,
    },
  ];
  const [isCopied, setIsCopied] = useState(false);
  const copy = () => {
    if (document.hasFocus()) {
      navigator.clipboard?.writeText(shareLink)
        .then(() => {
          setIsCopied(true);
          toast.success("Link copied");
        })
        .catch(err => {
          toast.error("Failed to copy text to clipboard");
        });
    }
  };
  
  

  return (
    <>
      <HeaderWrapper>
        <div>
          {location === "/" ? null : (
            <img
              className="pattern-upper-image"
              src={patternupperimage}
              alt=""
            />
          )}
        </div>
        <div className="main-wraper">
          <div
            className="header-left-side"
            onClick={() => navigate(`/${token}`)}
          >
            {logo ? (
              <div className="header-log-bydefaut">
                <img
                  className="header-logo"
                  src={imageBaseURL + clubDetails?.clubLogo}
                  alt=""
                />
                {/* <p className="logo-first-name">{logoName}</p> */}
              </div>
            ) : (
              <div className="header-log-bydefaut">
                <img
                  className="header-logo-bydefault"
                  src={bydefimageOutcoach}
                  // src={logopng}
                  alt=""
                />
              </div>
            )}
            <p className="header-haiding">{clubDetails?.clubName}</p>
          </div>

          <div className="main-wrap-right-side">
            <div className="wraper-right-side">
              <p className="header-link">Share Bookings Link</p>
              <img className="share-android-logo" src={shareandroid} alt="" />
            </div>
            <div className="social-media-logo-wrap">
              {socialmedialink?.length > 0 &&
                socialmedialink.map((ele, index) => (
                  <a
                    href={ele?.link}
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                  >
                    <img
                      className="all-logo-class"
                      src={ele.logo}
                      alt="socialmedialink"
                    />
                  </a>
                ))}
              <IoMdCopy className="copy-icon" style={{ color:isCopied ?"green" : "#000"}} onClick={copy} />
            </div>
          </div>
          <div className="share-responsive">
          <img className="share-android-logo" src={shareandroid} alt=""  onClick={() => setOpen(!open)}/>
          {
            open && <div className="social-media-logo-wrap-responsive">
              {socialmedialink?.length > 0 &&
                socialmedialink.map((ele, index) => (
                  <a
                    href={ele?.link}
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                  >
                    <img
                      className="all-logo-class"
                      src={ele.logo}
                      alt="socialmedialink"
                    />
                  </a>
                ))}
              <IoMdCopy className="copy-icon" style={{ color:isCopied ?"green" : "#000"}} onClick={copy} />
            </div>
          }
          </div>
        </div>
      </HeaderWrapper>
    </>
  );
}

export default Header;

const HeaderWrapper = styled.div`
  .main-wraper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 26px 40px 26px 40px;
  }
  .share-responsive{
    display: none;
  }

  .header-haiding {
    width: fit-content;
    height: 40px;
    font-weight: 700;
    size: 32px;
    display: flex;
    align-items: center;
  }

  .header-left-side {
    width: fit-content;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  .logo-first-name {
    color: #fff;
    font-weight: 700;
    font-size: 22px;
  }
  .header-log-container {
    width: 84.43px;
    height: 48px;
    background: #2e3038;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-logo {
    height: 100%;
    width: 100%;
    object-fit: cover;
    background: #fff;
  }

  .header-log-bydefaut {
    width: 100px;
    height: 60px;
    border-radius: 4px;
    background: #f7f6f4;
    border: 1px solid #0000004f;
  }
  .header-logo-bydefault {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .header-link {
    font-size: 20px;
    font-weight: 700;
  }

  .share-android-logo {
    width: 25px;
    height: 25px;
  }

  .social-media-logo-wrap {
    width: 219px;
    height: 41px;
    border-radius: 10px;
    border: 1px 0px 0px 0px;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16.21px;
  }

  .all-logo-class {
    width: 25.43px;
    height: 28.63px;
  }

  .wraper-right-side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }

  .main-wrap-right-side {
    display: flex;
    align-items: center;
    gap: 13px;
  }
  .pattern-upper-image {
    width: 100%;
    height: 16px;
  }

  .copy-icon {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
    cursor: pointer;
  }



  @media (max-width: 750px) {
    .main-wraper {
      padding:20px;
      align-items: center;
    }
    .share-responsive{
      display:block;
    }
    .social-media-logo-wrap-responsive{
      transition: ease-in-out 0.5s;     
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      display: flex;
      align-items: center;
      padding:20px;
      gap: 20px;
      position: absolute;
      right:10px;
      background: #fff;
      top:80px;

    }
    .main-wrap-right-side {
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
    }
    .main-wrap-right-side{
      display:none;
    }

    .wraper-right-side {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: end;
    }
  }

  @media (max-width: 409px) {
    .header-haiding {
      font-size: 15px;
      font-weight: 700;
    }
  }


 


 
`;
