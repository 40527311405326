import React from "react";
import styled from "styled-components";
import ButtonLoading from "../components/ButtonLoader";
import { colorslotCondition } from "../common/styleComponent";
import SlotCondition from "../common/SlotCondition";
import moment from "moment";
import ShowText from "../common/ShowText";
import { useLocation, useNavigate } from "react-router-dom";

const BookingSlot = ({ data }) => {
  const pathlocation = useLocation()?.pathname;
  const navigate = useNavigate();
  let endTime = new Date(data?.event?.endTime * 1000);
  let startTime = new Date(data?.event?.startTime * 1000);
  endTime = moment(endTime).format("hh:mm A");
  startTime = moment(startTime).format("hh:mm A");

  let price = data?.currencyType?.replace("(", "");
  price = price?.replace(")", "") + data?.pricePerTerm;

  const conditions = {
    label: `${startTime} -${endTime}`,
    color: colorslotCondition?.[data?.indicatorColor],
  };

  const handlebook = () => {
    navigate("/completebooking", {
      state: { ...data, pathlocation: pathlocation },
    });
  };

  return (
    <BookingSlotStyle>
    <div className="key-value-wrapper">
      <SlotCondition conditions={conditions} />
      <p className="para-heading">
        <ShowText text={data?.eventName} />
      </p>
      </div>
      <div className="key-value-wrapper">

      <div className="key-value-container">
        <p className="key-heading">Coach:</p>
        <p className="value-heading">
          <ShowText text={data?.couchName} />
        </p>
      </div>
      <div className="key-value-container">
        <p className="key-heading">Price:</p>
        <p className="value-heading"> {data?.pricePerTerm ? price : "--"}</p>
      </div>
      </div>
      <div className="btn-container">
        {data?.event?.status ? (
          <ButtonLoading
            handleClick={handlebook}
            width="230px"
            height="40px"
            text="Book"
          />
        ) : (
          <ButtonLoading
            type="disabled"
            width="230px"
            height="40px"
            text="Book"
            bgColor={"#28a3a950"}
          />
        )}
      </div>
    </BookingSlotStyle>
  );
};

export default BookingSlot;

const BookingSlotStyle = styled.div`
  max-width: 411px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px 16px;
  gap: 8px;
  .range-time-container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 12px;
  }
  .para-heading {
    font-weight: 700;
    font-family: Montserrat;
    font-size: 20px;
    line-height: 28px;
    color: #0b1e61;
  }
  .key-value-wrapper{
    max-width: 411px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;


  }
  .key-value-container {
    display: flex;
    gap: 5px;
    justify-content: start;
    align-items: center;
  }
  .key-heading {
    font-weight: 700;
    font-family: Montserrat;
    font-size: 20px;
    line-height: 32px;
  }
  .value-heading {
    font-weight: 400;
    font-family: Montserrat;
    font-size: 16px;
    line-height: 32px;
    color: #0b1e61;
  }
  .btn-container {
    width: fit-content;
    padding: 10px 0;
  }
  @media (max-width: 600px) {
    .btn-container {
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
    }

  }
  @media (max-width: 600px) and (min-width: 540px) {
    max-width: unset;
 
    
    
    .key-value-wrapper{
      max-width: unset;
      display:grid;
      width:100%;
      grid-template-columns: repeat(2,1fr);
      place-items: center;
    }
  }
`;
