import SearchMelbourne from "../../pages/SearchMelbourne";
import Complitebooking from "../../pages/Complitebooking";
import Landing from "../../pages/Landing";

export const Paths = [
    {
        path:"/",
        components: <Landing />
    },
    {
        path:"/searchMelbourne",
        components: <SearchMelbourne />
    },
    {
        path:"/completebooking",
        components: <Complitebooking/>
    },

    
]