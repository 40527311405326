import React, { useEffect, useState } from "react";
import styled from "styled-components";
import outcoachlogo from "../assets/Outcoach logo 1.svg";
import BookingSlot from "../components/BookingSlot";
import CalenderDateShow from "../components/calender";
import NotDataFound from "../utils/NotDataFound";
import AppLoader from "../Loader/AppLoader";
import moment from "moment";
import { colorSelecterfilter, manipulateData } from "../utils/constantPath";
import { toast } from "react-toastify";
import { getListEventsMonthWise } from "../services/Collection";
import { useSelector } from "react-redux";

const getDaysInMonth = (month, year) => new Date(year, month, 0).getDate();

function Oneoffevent({ filterselected, filterColor }) {
  const clubId = useSelector((store) => store?.login?.data?.id);
  const [oneOffListData, setOneOfflistData] = useState();
  const [oneOffListDatatotal, setOneOfflistDatatotal] = useState();
  const [currentMonth, setCurrentMonth] = useState();
  const [alreadySelectedDate, setAlreadySelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const todayDate = new Date().toISOString().slice(0, 10);
  const [eventsOnDate, setEventsOnDate] = useState([{ date: 0, count: 4 }]);

  const getCountDate = (list) => {
    let newArr = [];
    let date = [];
    list?.forEach((ele) => {
      if (date?.includes(ele?.date)) {
        const n = newArr.map((e) =>
          e?.date == ele?.date ? { ...e, count: e?.count + 1 } : e
        );
        newArr = n;
      } else {
        date.push(ele?.date);
        newArr.push({
          date: ele?.date,
          count: 1,
          momentDate: moment(ele?.date).format("DD"),
        });
      }
    });
    setEventsOnDate(newArr);
  };

  const monthgetApidata = async (startDate, endDate, alreadySelect) => {
    const params = new URLSearchParams();
    params.append("clubId", clubId);
    params.append("startDate", startDate);
    params.append("endDate", endDate);
    if (filterselected?.hasOwnProperty("eventType")) {
      if (filterselected?.eventType == "1") {
        params.append("eventType", filterselected?.eventType);
        if (filterselected?.hasOwnProperty("clubEventTypeId")) {
          params.append("clubEventTypeId", filterselected?.clubEventTypeId);
        }
        if (filterselected?.hasOwnProperty("venueId")) {
          params.append("venueId", filterselected?.venueId);
        }
        setLoading(true);
        const res = await getListEventsMonthWise(params);
        if (res?.status == 200) {
          const result = await manipulateData(res?.data);
          let updateResult = result.sort(function(a, b){return a?.event?.startTime - b?.event?.startTime});
          const selectdayData = updateResult?.filter(
            (el) => alreadySelect == el?.date
          );
          setOneOfflistData(selectdayData);
          setOneOfflistDatatotal(updateResult);
          getCountDate(updateResult);
        } else {
          toast.error(
            res?.response?.data?.message ||
              res?.message ||
              "Something went wrong"
          );
        }
        setLoading(false);
      }
    }
  };

  const handleselectDate = async (selectDate) => {
    const alreadySelect = selectDate.format("YYYY-MM-DD");
    const startDate = selectDate.format("YYYY-MM-01");
    const month = moment(startDate).format("MM");
    const year = moment(startDate).format("YYYY");
    const mouthlastDate = getDaysInMonth(month, year);
    const endDate = moment(startDate).format(`YYYY-MM-${mouthlastDate}`);
    if (currentMonth == month) {
      const selectdayData = oneOffListDatatotal?.filter(
        (el) => alreadySelect == el?.date
      );
      setOneOfflistData(selectdayData);
    } else {
      setCurrentMonth(month);
      monthgetApidata(startDate, endDate, alreadySelect);
    }
    setAlreadySelectedDate(alreadySelect);
  };

  useEffect(() => {
    let today = moment(todayDate).format("YYYY-MM-DD");
    if (alreadySelectedDate) today = alreadySelectedDate;
    const month = moment(todayDate).format("MM");
    const year = moment(todayDate).format("YYYY");
    const mouthlastDate = getDaysInMonth(month, year);
    const startDate = moment(todayDate).format("YYYY-MM-01");
    const endDate = moment(todayDate).format(`YYYY-MM-${mouthlastDate}`);
    monthgetApidata(startDate, endDate, today);
    setCurrentMonth(month);
  }, [filterselected]);

  const listmap = colorSelecterfilter(oneOffListData, filterColor);
  return (
    <Oneoffeventwrap>
      <div className="main-wrap-hadding">
        <div className="wrapper-hadding">
          <p className="upcoming-hadding">One-off Events</p>
          <div className="right-side-hadding-wrap">
            <p className="powered-hadding">Powered by</p>
            <img src={outcoachlogo} alt="" />
          </div>
        </div>
        <div className="border-bottom"></div>

        <div className="wrapper-calender-book">
          <div className="one-off-event-calender-side">
            <div className="calender-wraper">
              <CalenderDateShow
                handleselectDate={handleselectDate}
                eventsOnDate={eventsOnDate}
              />
            </div>
          </div>

          <div className="one-off-event-book-side">
            {loading ? (
              <div className="loading-container">
                <AppLoader />
              </div>
            ) : listmap?.length === 0 ? (
              <NotDataFound />
            ) : (
              listmap?.map((el) => {
                return (
                  <div>
                    <p className="data-heading-pare ">
                      {moment(el?.date).format("Do MMM  YYYY")}
                    </p>
                    <BookingSlot data={el} backgroundcolor={false} />
                    <div className="one-off-compo-border-bottum"></div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </Oneoffeventwrap>
  );
}

export default Oneoffevent;

const Oneoffeventwrap = styled.div`
  .one-offf-event-filter-wrap {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 24px 32px 24px 32px;
    border-style: none;
    outline: none;
  }
  .data-heading-pare {
    font-weight: 700;
    font-family: Inter;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: 8px;
    background: #e8edfc;
    font-size: 18px !important;
    color: #0b1e61;
    text-align: start;
    width: fit-content;
  }

  .upcoming-hadding {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
  }

  .powered-hadding {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
  }
  .loading-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .wrapper-hadding {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .right-side-hadding-wrap {
    display: flex;
    align-items: center;
    gap: 9px;
  }
  .main-wrap-hadding {
    width: calc(100% - 30px);
    margin: auto;
    padding: 25.5px 32px;
    border-radius: 16px 16px 0px 0px;
    background: #ffffff;
    box-shadow: 1px 1px 8px 0px #aaaaaa80;
    @media (max-width: 600px) {
      padding:20px;
    }
  }
  .border-bottom {
    border-bottom: 1px solid #aaaaaa80;
  }
  .wrapper-calender-book {
    width: 100%;
    display: flex;
  }
  .one-off-event-calender-side {
    width: 45%;
    border-right: 1px solid #aaaaaa80;
  }
  .one-off-event-book-side {
    width: 55%;
    margin: 29px;
    overflow-y: scroll;
    height: 460px;

    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: normal;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #28a3a9;
      border-radius: 10px;
    }
  }
  .book-date {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    width: 148px;
    height: 32px;
    padding: 4px 16px 4px 16px;
    border-radius: 8px;
    background: #e8edfc;
  }

  .calender-wraper {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .one-off-compo-border-bottum {
    border-bottom: 1px solid #aaaaaa80;
    margin-bottom: 19px;
  }

  @media (max-width: 900px) {
    .wrapper-calender-book {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: none;
    }
    .one-off-event-book-side{
      width:100%;
      display:grid;
      Grid-template-columns:repeat(2,1fr);
    }
  }

  @media (max-width: 600px) {
    .right-side-hadding-wrap {
      display: none;
    }
    .one-off-event-book-side{
      width:100%;
      display:grid;
      Grid-template-columns:repeat(1,1fr);
      align-items:center;
      justify-content:center;
    }
  }

  @media (max-width: 600px) {
    .upcoming-hadding {
      margin-bottom: 10.5px;
    }
  }

  @media (max-width: 335px) {
    .upcoming-hadding {
      font-size: 20px;
    }
  }

  @media (max-width: 950px) {
    .one-off-event-calender-side {
      width: 100%;
      border-right: none;
    }
  }

  @media (max-width: 680px) {
    .one-off-event-book-side {
      width: 100%;
      margin:20px;
    }
  }
`;
