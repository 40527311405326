import { memo, useState } from "react";
import styled from "styled-components";
import Filtter from "../components/Filtter";
import Bookcompo from "../components/Bookcompo";
import outcoachlogo from "../assets/Outcoach logo 1.svg";
import { useLocation } from "react-router-dom";
import { BookingBoxWrapper } from "./Landing";
import AppLoader from "../Loader/AppLoader";
import { HorizontaLine } from "../common/styleComponent";
import NotDataFound from "../utils/NotDataFound";
import { useSelector } from "react-redux";
import Oneoffevent from "./Oneoffevent";
import { toast } from "react-toastify";
import { getListingApi } from "../services/Collection";
import { colorSelecterfilter, manipulateData } from "../utils/constantPath";
import ButtonLoader from "../components/ButtonLoader";
import navarrowright from "../assets/nav-arrow-right.svg";
import moment from "moment";

function SearchMelbourne() {
  const data = useLocation().state;
  const clubId = useSelector((store) => store?.login?.data?.id);

  const [OneOffComponents, setOneOffComponents] = useState(
    (data?.defaultValue?.class && data?.defaultValue?.class?.value == 1) ||
      false
  );
  const [regularlisting, setRegularlisting] = useState(data?.listing || []);
  const [loading, setLoading] = useState(false);
  const [filterselected, setFilterselected] = useState(null);
  const [filterColor, setFilterColor] = useState({
    Available: true,
    almostFull: true,
    waitList: true,
  });
  const [pageNumber, setPageNumber] = useState(2);
  const [totalCount, setTotalCount] = useState(data?.totalCount);
  const [viewMoreLoader, setVeiwMoreLoader] = useState(false);

  const createGroupLastestEvent = (payload) => {
   
    let updatedArray = [];
    let tempIds = [];
    let date = "";

    for (let i = 0; i < payload.length; i++) {
      const element = payload[i];
      if (tempIds?.includes(element?.event?.id)) {
        if (moment(date) > moment(element?.date)) {
          updatedArray[updatedArray?.length - 1] = element;
          date = element?.date;
        }
      } else {
        tempIds.push(element?.event?.id);
        updatedArray.push(element);
        date = element?.date;
      }
    }

    return updatedArray;
  };

  const handleFilter = async (
    selectValue,
    colorFilter,
    defaultVal,
    onlyColorFilter
  ) => {
    const params = new URLSearchParams();
    if (onlyColorFilter) {
      setFilterColor(colorFilter);
    } else {
      params.append("clubId", clubId);
      params.append("pageNumber", 1);
      if (selectValue?.hasOwnProperty("eventType")) {
        params.append("eventType", selectValue?.eventType);
      }
      if (selectValue?.hasOwnProperty("clubEventTypeId")) {
        params.append("clubEventTypeId", selectValue?.clubEventTypeId);
      }
      if (selectValue?.hasOwnProperty("venueId")) {
        params.append("venueId", selectValue?.venueId);
      }
      if (clubId) {
        setLoading(true);
        setFilterselected(selectValue);
        setFilterColor(colorFilter);
        if (
          selectValue?.hasOwnProperty("eventType") &&
          selectValue?.eventType == 2
        ) {
          const res = await getListingApi(params);

          if (res?.status === 200) {
            const updatedGroup =  createGroupLastestEvent(res?.data);
            const Filterlisting = await manipulateData(updatedGroup);
            setRegularlisting(Filterlisting);
            setTotalCount(res?.extraData?.count);
          } else {
            toast.error(
              res?.response?.data?.message ||
                res?.message ||
                "Something went wrong"
            );
          }
        }
        setTimeout(() => {
          setLoading(false);
        },500);
      
      } else {
        toast.error("Not found club Id!");
      }
      if (selectValue?.eventType == 1) {
        setOneOffComponents(true);
      } else {
        setOneOffComponents(false);
      }
    }
  };


  const paginationListing = async () => {
    const params = new URLSearchParams();
    params.append("clubId", clubId);
    params.append("pageNumber", pageNumber);
    if (filterselected?.hasOwnProperty("eventType")) {
      params.append("eventType", filterselected?.eventType);
    }
    if (filterselected?.hasOwnProperty("clubEventTypeId")) {
      params.append("clubEventTypeId", filterselected?.clubEventTypeId);
    }
    if (filterselected?.hasOwnProperty("venueId")) {
      params.append("venueId", filterselected?.venueId);
    }
    if (clubId) {
      setVeiwMoreLoader(true);
      const res = await getListingApi(params);
      if (res?.status == 200) {
        const updatedGroup =  createGroupLastestEvent(res?.data);
        const filterlisting = await manipulateData(updatedGroup);
        setRegularlisting((previous) => [...previous, ...filterlisting]);
        setPageNumber(pageNumber + 1);
      } else {
        toast.error(
          res?.response?.data?.message || res?.message || "Something went wrong"
        );
      }
      setVeiwMoreLoader(false);
    } else {
      toast.error("Club Id NOT FOUND!");
    }
  };

  useState(()=>{
    setFilterselected(data?.selectType||null)
  },[])
  
  const listmap = colorSelecterfilter(regularlisting, filterColor);
  return (
    <>
      <Melbourne>
        <div className="wrap-filter">
          <Filtter
            data={data}
            defaultValue={data?.defaultValue}
            allowClear={true}
            handleFilter={handleFilter}
          />
        </div>
        {/* select event type === 1 One Off select */}
        {OneOffComponents || filterselected?.eventType == 1 ? (
          <Oneoffevent
            filterselected={filterselected}
            filterColor={filterColor}
          />
        ) : (
          <Upcommingclass>
            <div className="main-wrap-hadding">
              <div className="wrapper-hadding">
                <p className="upcoming-hadding">Regular Events</p>
                <div className="right-side-hadding-wrap">
                  <p className="powered-hadding">Powered by</p>
                  <img src={outcoachlogo} alt="" />
                </div>
              </div>
              <HorizontaLine height="1px" margin="0 0 24px 0" />
              {loading ? (
                <div className="loading-container">
                  <AppLoader />
                </div>
              ) : listmap?.length === 0 ? (
                <NotDataFound />
              ) : (
                <>
                  <BookingBoxWrapper>
                    {listmap?.map((el, index) => (
                      <Bookcompo data={el} key={index} />
                    ))}
                  </BookingBoxWrapper>
                </>
              )}
              {/* <Viewmorewrapper>
                {!(totalCount === regularlisting?.length) && (
                  <div className="main-wrap-viewmore-button">
                    {viewMoreLoader ? (
                      <div className="view-more-wrap">
                        <ButtonLoader onlyLoading={true} />
                      </div>
                    ) : (
                      <div
                        className="view-more-wrap"
                        onClick={paginationListing}
                        role="button"
                      >
                        <button className="view-more-hadding">View more</button>
                        <img
                          className="nav-arrow-right"
                          src={navarrowright}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                )}
              </Viewmorewrapper> */}
            </div>
          </Upcommingclass>
        )}
      </Melbourne>
    </>
  );
}

export default memo(SearchMelbourne);

const Melbourne = styled.div`
  .wrap-filter {
    width: 100%;
    border-radius: 16px;
    padding: 24px 32px 24px 32px;
    border-style: none;
    outline: none;
    @media (max-width: 1000px) {
      padding:20px
  
  }
  }
`;

const Upcommingclass = styled.div`
  width: 100%;
  min-height: 400px;
  .upcoming-hadding {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
  }
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
  }

  .powered-hadding {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
  }

  .wrapper-hadding {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .right-side-hadding-wrap {
    display: flex;
    align-items: center;
    gap: 9px;
  }
  .main-wrap-hadding {
    width: calc(100% - 64px);
    margin: auto;
    padding: 25.5px 32px;
    border-radius: 16px 16px 0px 0px;
    background: #ffffff;
    box-shadow: 1px 1px 8px 0px #aaaaaa80;
  
  }
  .border-bottom {
    border-bottom: 1px solid #aaaaaa80;
    margin-bottom: 24px;
  }
  @media (max-width: 600px) {
    .main-wrap-hadding {
      padding:10px !important;
      width:calc(100% - 20px)
    }
    .wrapper-hadding{
      flex-direction:column;
      align-items:start;
    }
  }

  
  

`;

// const Viewmorewrapper = styled.div`
//   .view-more-wrap {
//     width: 411px;
//     height: 64px;
//     border-radius: 24px;
//     border-style: none;
//     outline: none;
//     background: #ffffff;
//     border: 1px solid #33374e;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     gap: 8px;
//     cursor: pointer;
//   }
//   .view-more-hadding {
//     font-family: Montserrat;
//     font-size: 20px;
//     font-weight: 700;
//     background: transparent;
//     border-style: none;
//     cursor: pointer;
//   }
//   .nav-arrow-right {
//     width: 24px;
//     height: 24px;
//   }
//   .main-wrap-viewmore-button {
//     width: 100%;
//     height: 64px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-top: 32px;
//   }

//   @media (max-width: 620px) {
//     .view-more-wrap {
//       width: 380px;
//     }
//   }

//   @media (max-width: 555px) {
//     .view-more-wrap {
//       width: 300px;
//       height: 60px;
//     }
//   }

//   @media (max-width: 450px) {
//     .view-more-wrap {
//       width: 200px;
//       height: 55px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }
//   }
// `;
