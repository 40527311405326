import { getVenues, getclubevent } from "../services/Collection";

export const handlegetclubevent = async (clubId) => {
  const params = new URLSearchParams();
  params.append("clubId", clubId);
  const res = await getclubevent(params);
  if (res?.status == 200) {
    let modifyData = res?.data?.map((e) => ({
      ...e,
      label: e?.eventType,
      value: e?.id,
    }));
    return modifyData;
  }
};

export const handlegetvanues = async (clubId) => {
  const params = new URLSearchParams();
  params.append("clubId", clubId);
  const res = await getVenues(params);
  if (res.status == 200) {
    const modifyData = res?.data?.map((e) => ({
      ...e,
      label:((e?.venueName==null && e?.venueType==2) || (e?.venueName==undefined && e?.venueType==2))?"Online":e?.venueName,
      value: e?.id,
    }));
    return modifyData;
  }
};

export const colorSelecterfilter = (list, colorFilter) => {
  if (
    colorFilter?.Available &&
    colorFilter?.almostFull &&
    colorFilter?.waitList
  ) {
    return list
  } else {
    const fiterArrray = [];
    list?.forEach((ele) => {
      if (
        ele?.indicatorColor === "availableColor" &&
        colorFilter?.Available
      ) {
        fiterArrray.push(ele);
      } else if (
        ele?.indicatorColor === "fullColor" &&
        colorFilter?.almostFull
      ) {
        fiterArrray.push(ele);
      } else if (
        ele?.indicatorColor === "waitColor" &&
        colorFilter?.waitList
      ) {
        fiterArrray.push(ele);
      }
    });
    return fiterArrray;
  }
};


export const manipulateData = async(data) => {
  let tempArray = [];
  data?.forEach((c) => {
    // if (
    //   new Date(c.date)?.getDate() === new Date()?.getDate() &&
    //   new Date(c.date)?.getMonth() === new Date()?.getMonth()
    // ) {

    let tempcouchName = [];
    c?.attendees?.forEach(
      (at) =>
        at?.coachMemberData &&
        tempcouchName.push(
          at?.coachMemberData?.user?.firstName +
            " " +
            at?.coachMemberData?.user?.lastName
        )
    );
    let maxLimit = Math.floor(c?.membersLimit / 3);
    let currentBooking = c?.attendees?.length;
    tempArray?.push({
      ...c,
      indicatorColor:
        maxLimit >= currentBooking
          ? "availableColor"
          : maxLimit * 2 >= currentBooking
          ? "fullColor"
          : "waitColor",
      couchName: tempcouchName,
    });
    // }
  });
  return tempArray;
};

