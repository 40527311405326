import styled from "styled-components";
import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../components/ButtonLoader";

const PageNotfound404 = () => {
  const navigate = useNavigate();

  return (
    <PageNotfound404Style>
      <h1 className="text-404-h1">404</h1>
      <p className="error-para-first">OPPS! PAGE NOT FOUND</p>
      <div>
        <p className="error-para-second">
          Sorry, the page your're looking for doesn't exist.
        </p>
      </div>
      <ButtonLoader
        padding="5px 25px"
        handleClick={() => {
          navigate(-1);
        }}
        text="RETURN"
        bgColor="#031b1c"
      />
    </PageNotfound404Style>
  );
};

export default PageNotfound404;

const PageNotfound404Style = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  gap: 20px;
  background: black;

  .text-404-h1 {
    margin: -20px;
    text-align: center;
    font-size: 82px;
    background: -webkit-linear-gradient(#fe22f6, #0edbfa);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .error-para-first {
    text-align: center;
    font-size: 32px;
    background: -webkit-linear-gradient(#fe22f6, #9c4f4f, #0edbfa);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .error-para-second {
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    opacity: 0.9;
    font-family: "Poppins";
  }
  .btn-error-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error-btn {
    font-weight: 400;
    line-height: 24px;
    padding: 0.3rem 0.8rem;
    letter-spacing: 0.5px;
    color: #fff;
    cursor: pointer;
    border: none;
    background: linear-gradient(
      97.43deg,
      rgb(17 105 223) 0%,
      rgb(45 184 229) 100%
    );
    border-color: #6777ef;
    vertical-align: middle;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    :hover {
      background: linear-gradient(
        97.43deg,
        rgb(47, 128, 237) 0%,
        rgb(86, 204, 242) 100%
      );
    }
  }
`;
