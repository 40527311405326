import * as Yup from "yup";

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const bookingvalidationMyself = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .min(2, "First Name must be at least 2 characters")
    .max(30, "First Name must be at least 30 characters"),
  lastName: Yup.string()
    .required("Last Name is required")
    .min(2, "Last Name must be at least 2 characters")
    .max(30, "Last Name must be at least 30 characters"),
 
  email: Yup.string()
    .trim()
    .required("Email is Required")
    .matches(emailRegex, "Invalid email"),
});

export const bookingvalidationWithchildemail = Yup.object().shape({
  childFirstName: Yup.string()
    .required("Child first Name is required")
    .min(2, "Child first Name must be at least 2 characters")
    .max(30, "Child first Name must be at least 30 characters"),
  childLastName: Yup.string()
    .required("Child last Name is required")
    .min(2, "Child last Name must be at least 2 characters")
    .max(30, "Child last Name must be at least 30 characters"),
  childEmail: Yup.string()
    .trim()
    .required("Child email is Required")
    .matches(emailRegex, "Invalid email"),
});
export const bookingvalidationWithchild = Yup.object().shape({
  childFirstName: Yup.string()
    .required("Child first Name is required")
    .min(2, "Child first Name must be at least 2 characters")
    .max(30, "Child first Name must be at least 20 characters"),
  childLastName: Yup.string()
    .required("Child last Name is required")
    .min(2, "Child last Name must be at least 2 characters")
    .max(30, "Child last Name must be at least 20 characters"),
  // childEmail: Yup.string()
  //   .required("Email is Required")
  //   .email("Invalid email"),
});
