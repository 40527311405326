import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import styled from "styled-components";
import {
  createPaymentIntentApi,
  getRetriveBooking,
} from "../services/Collection";
import { toast } from "react-toastify";
import AppLoader from "../Loader/AppLoader";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const CheckoutForm = ({
  clientSecret,
  id,
  handleBookingApi,
  values,
  setPaymentModal,
}) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!elements || !stripe) {
      return;
    }
    try {
      const elementsSubmitResponse = await elements.submit();

      if (elementsSubmitResponse.error) {
        toast.error(
          elementsSubmitResponse.error.message ||
            "Something went wrong. Please try again."
        );
        return;
      }
      setLoading(true);

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {},
        redirect: "if_required",
      });

      if (error) {
        setLoading(false);
        toast.error(error.message || "Something went wrong. Please try again.");
        return;
      } else {
        let bookingResponse = await getRetriveBooking(id);
        setLoading(false);
        setPaymentModal(false);
        if (bookingResponse?.status === 200) {
          await handleBookingApi(values);
        } else {
          toast.error(
            bookingResponse?.data?.message ||
              bookingResponse?.message ||
              "Something went wrong. Please try again."
          );
        }
      }
    } catch (err) {
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <PaymentElement />
      <button type="submit" disabled={!stripe || !elements}>
        {loading ? "Processing..." : "Pay"}
      </button>
    </FormWrapper>
  );
};

const Card = ({ data, email, handleBookingApi, values, setPaymentModal }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchClientSecret = async () => {
      const payload = {
        clubId: data?.clubId,
        email: email,
        currencyType: data?.currencyType?.split(" ")?.[0],
        amount: data?.pricePerTerm,
      };
      setLoading(true);

      let res = await createPaymentIntentApi(payload);
      setLoading(false);
      if (res?.data?.clientSecret) {
        setClientSecret(res.data.clientSecret);
        setId(res.data.id);
      } else {
        toast.error(
          res?.data?.message || res?.message || "Something went wrong"
        );
      }
    };

    fetchClientSecret();
  }, [data, email]);

  const options = {
    clientSecret,
    appearance: {
      /*...*/
    },
  };

  return (
    <CardWrapper>
      {loading && <AppLoader />}
      {clientSecret && !loading && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            clientSecret={clientSecret}
            email={email}
            data={data}
            id={id}
            handleBookingApi={handleBookingApi}
            values={values}
            setPaymentModal={setPaymentModal}
          />
        </Elements>
      )}
    </CardWrapper>
  );
};

export default Card;
const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const FormWrapper = styled.form`
 min- width: 300px;
 width: 100%;
  button {
    margin-top: 35px;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
    border-style: none;
    outline: none;
    background: #28a3a9;
    color: white;
    font-size: 16px;
    font-weight: 500;
    &:hover {
      background: #0c6569;
    }
    
  }
 
  
`;
