import { memo, useRef, useState } from "react";
import styled from "styled-components";
import { Formik } from "formik";
import InputFields from "./InputField";
import ButtonLoading from "../components/ButtonLoader";
import Checkboxs from "../components/Checkbox";
import { bookingvalidationMyself } from "./formValidation";
import { Dimond_alt } from "../utils/imageSvg";
import BookingForCard from "../components/BookingForCard";
import { AppWarpper, ClosebtnX } from "../common/styleComponent";
import BookingConfirmed from "../modal/BookingConfirmed";
import {
  postBookingApiChild,
  postBookingMyselfApi,
  postVerifyOtpApi,
} from "../services/Collection";
import { useLocation, useNavigate } from "react-router-dom";
import ChildForm from "./ChildForm";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import VerifyOtpModal from "../modal/VerifyOtpModal";
import moment from "moment";
import Payment from "../modal/Payment";

const checkBoxValues = {
  label:
    "I agree to the Terms and Conditions set by the business that I am booking with. I can request a copy of the Terms and Conditions by contacting the business",
  value: "Argree",
  checkColor: "#000",
};
const FormikFieldMyself = [
  {
    name: "firstName",
    type: "text",
    placeholder: "First Name",
    maxlength: "30",
    // className: "input-class-name",
    // eyes:true,
  },
  {
    name: "lastName",
    type: "text",
    placeholder: "Last Name",
  },
  {
    phoneno: true,
    name: "userMobile",
    type: "text",
    placeholder: "Mobile",
    maxlength: "10",
  },
  {
    name: "email",
    type: "email",
    placeholder: "Email",
  },
];

const initialValue1 = {};
for (let i = 0; i < FormikFieldMyself?.length; i++) {
  initialValue1[FormikFieldMyself[i]?.name] = "";
}

const BookingForm = () => {
  const data = useLocation()?.state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [verifyModal, setVerifyModal] = useState(false);
  const [verifyEmailPersent, setVerifyEmailPersent] = useState(false);
  const [verifyEmailChild, setVerifyEmailChild] = useState(false);
  const [verifyEmailLoading, setVerifyEmailLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [OtpHashed, setOptHashed] = useState(false);
  const [termCondition, setTermCondition] = useState(false);
  const [selectChild, setSelectChild] = useState("myself");
  const [selectChildEmail, setSelectChildEmail] = useState("yes");
  const [valueOfpersent, setValueOfpersent] = useState({});
  const [values, setvalues] = useState({});
  const formRef = useRef();
  const token = useSelector((store) => store?.login?.data?.token);
  let endTime = moment(new Date(data?.actualEndTime * 1000)).format();
  let startTime = new Date(data?.actualStartTime * 1000);
  startTime = moment(startTime).format("hh:mm A");
  endTime = moment(endTime).format("hh:mm A");
  const [phoneData, setPhoneData] = useState({
    countryCode: "",
    phoneNumber: "",
  });

  //---Child form setvalues  ----
  const submitHandleChild = () => {
    if(formRef.current) {
      setTimeout(() => {
        formRef.current.handleSubmit();
      }, 300);
    }
  };


  //--Api Child and persent booking form sumbit----//
  const submitChild = async (child) => {
    const childValue = child;
    let verifyemail = false;
    
      if (selectChildEmail === "no") {
        delete childValue["childEmail"];
        verifyemail = true;
      } else {
        verifyemail = verifyEmailChild;
      }

      const childPersent = {
        eventName: data?.eventName,
        bookingType: (data && data.isAcceptOnlinePayment) ? "online" : "offline",
        timing: `${startTime} - ${endTime}`,
        date: data?.date,
        currency: data?.currencyType,
        price: data?.pricePerTerm ? data?.pricePerTerm : 0,
        clubId: data?.clubId,
        eventId: data?.eventId,
        parentFirstName: valueOfpersent?.firstName,
        parentLastName: valueOfpersent?.lastName,
        parentMobile: phoneData?.phoneNumber,
        dialingCode: phoneData?.countryCode,
        parentEmail: valueOfpersent?.email,
        ...childValue,
        leadRequestType: "parent",
      };

      if (data?.clubId) {
        if (verifyemail) {
          setLoading(true);
          const res = await postBookingApiChild(childPersent);
          if (res?.status === 200) {
            setConfirmModal(true);
          } else {
            toast.error(
              res?.response?.data?.message ||
                res?.message ||
                "Something went wrong"
            );
          }
          setLoading(false);
        } else {
          toast.error("Please verify child email");
        }
      } else {
        toast.error("Club Id NOT FOUND!");
      }
    
  };

  const submitHandle = async (values) => {
    setEmail(values?.email);
    setvalues(values);
    if (!phoneData.phoneNumber) {
      toast.warning("Please enter phone number ");
      return;
    }
    if (verifyEmailPersent) {
      if (data?.isAcceptOnlinePayment) {
        setPaymentModal(true);
      } else {
        await handleBookingApi(values);
      }
    } else {
      toast.error("Please click on arrow to verify your email ");
    }
  };

  const handleBookingApi = async (values) => {
    const persent = {
      firstName: values?.firstName,
      bookingType: (data && data.isAcceptOnlinePayment) ? "online" : "offline",
      lastName: values?.lastName,
      userMobile: phoneData?.phoneNumber,
      email: values?.email,
      dialingCode: phoneData?.countryCode,
      clubId: data?.clubId,
      eventId: data?.eventId,
      eventName: data?.eventName,
      timing: `${startTime} - ${endTime}`,
      date: data?.date,
      currency: data?.currencyType,
      price: data?.pricePerTerm ? data?.pricePerTerm : 0,
    };
    setValueOfpersent(persent);

    if (selectChild === "child") {
      submitHandleChild();
    } else {
      if (data?.clubId) {
        setPaymentModal(false);
        setLoading(true);
        const res = await postBookingMyselfApi({
          leadRequestType: "myself",
          ...persent,
        });
        if (res?.status === 200) {
          setPaymentModal(false);
          setConfirmModal(true);
        } else {
          toast.error(
            res?.response?.data?.message ||
              res?.message ||
              "Something went wrong"
          );
        }
        setLoading(false);
      } else {
        toast.error("Club Id NOT FOUND!");
      }
    }
  };

  // Back navigation close button cross//
  const navigateBack = () => {
    navigate(`/${token}`);
  };

  async function emailOnchange(email) {
    let verifyObj = {
      apiType: "email",
      email: email,
      clubId: data?.clubId,
      eventId: data?.eventId,
    };
    if (data?.clubId) {
      setVerifyEmailLoading(true);
      const res = await postVerifyOtpApi(verifyObj);
      if (res?.status === 200) {
        setVerifyModal(true);
        setOptHashed(res?.data?.hashed);
      } else {
        toast.error(
          res?.response?.data?.message || res?.message || "Something went wrong"
        );
      }
    } else {
      toast.error("Club Id NOT FOUND!");
    }
    setVerifyEmailLoading(false);
  }
  return (
    <>
      {confirmModal && <BookingConfirmed navigateBack={navigateBack} />}
      {verifyModal && (
        <VerifyOtpModal
          OtpHashed={OtpHashed}
          setVerifyEmail={setVerifyEmailPersent}
          closeModal={setVerifyModal}
        />
      )}
      {paymentModal && (
        <Payment
          values={values}
          setPaymentModal={setPaymentModal}
          data={data}
          email={email}
          handleBookingApi={handleBookingApi}
        />
      )}
      <AppWarpper>
        <BookingFormStyle>
          <ClosebtnX onClick={navigateBack}>x</ClosebtnX>
          <div className="booking-complete-logo">
            <div className="complete-img">
              <img src={Dimond_alt} alt="" />
            </div>
            <p className="complete-para">Complete your booking</p>
          </div>

          <div className="container-from-card">
            <BookingForCard data={data} />
            <Formik
              validationSchema={bookingvalidationMyself}
              initialValues={{
                ...initialValue1,
              }}
              onSubmit={submitHandle}
            >
              {({ errors, touched, handleSubmit }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <div className="form-container">
                    <div className="radio-btn-container">
                      <div className="radio-box">
                        <input
                          type="radio"
                          name="selectchild"
                          value="myself"
                          className="radio-btn"
                          defaultChecked={true}
                          onChange={(e) => setSelectChild(e.target.value)}
                        />
                        <p className="radio-label">For myself</p>
                      </div>
                      <div className="radio-box">
                        <input
                          type="radio"
                          name="selectchild"
                          value="child"
                          className="radio-btn"
                          onChange={(e) => setSelectChild(e.target.value)}
                        />
                        <p className="radio-label">For my child</p>
                      </div>
                    </div>

                    <p className="form-header">Your details</p>
                    {FormikFieldMyself?.map((fieldValue, index) => {
                      return (
                        <InputFields
                          key={fieldValue?.name}
                          field={fieldValue}
                          errors={errors}
                          touched={touched}
                          onChange={emailOnchange}
                          verifyEmail={verifyEmailPersent}
                          verifyEmailLoading={verifyEmailLoading}
                          setPhoneData={setPhoneData}
                        />
                      );
                    })}
                    {selectChild === "child" && (
                      <ChildForm
                        formRef={formRef}
                        data={data}
                        setLoading={setLoading}
                        submitChild={submitChild}
                        selectChildEmail={selectChildEmail}
                        setSelectChildEmail={setSelectChildEmail}
                        verifyEmailChild={verifyEmailChild}
                        setVerifyEmailChild={setVerifyEmailChild}
                      />
                    )}
                    <Checkboxs
                      onChange={(e) => setTermCondition(e?.target?.checked)}
                      checkBoxValues={checkBoxValues}
                    />
                    {termCondition ? (
                      <ButtonLoading
                        // handleClick={submitHandleChild}
                        loading={loading}
                        text={
                          data?.isAcceptOnlinePayment ? " Pay and Book" : "Book"
                        }
                      />
                    ) : (
                      <ButtonLoading
                        type="disabled"
                        bgColor="#b3b3b3"
                        text={
                          data?.isAcceptOnlinePayment ? " Pay and Book" : "Book"
                        }
                      />
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </BookingFormStyle>
      </AppWarpper>
    </>
  );
};

export default memo(BookingForm);

const BookingFormStyle = styled.div`
  max-width: 1200px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .booking-complete-logo {
    display: flex;
    gap: 16px;
    justify-content: start;
    align-items: center;
  }
  .complete-img {
    padding: 8px;
    background: white;
    width: fit-content;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .complete-para {
    color: #fff;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 28px;
    font-size: 24px;
  }

  .container-from-card {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    @media (max-width: 900px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .form-container {
    background: #f7f6f4;
    max-width: 743px;
    height: fit-content;
    border-radius: 16px;
    padding: 48px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
  }
  .form-container2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
  }
  .form-header {
    color: #333333;
    font-weight: 700;
    line-height: 28px;
    font-size: 20px;
    margin: 10px 0;
  }
  .radio-btn-container {
    width: 100%;
    display: flex;
    gap: 32px;
  }
  .radio-box {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 16px;
    width: fit-content;
  }
  .radio-btn {
    accent-color: #28a3a9;
    width: 20px;
    height: 20px;
  }
  .radio-label {
    font-family: "Inter";
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }
  @media (max-width: 500px) {
    .form-container {
      width: 100%;
      padding: 20px;
      gap: unset;
    }
    .radio-btn-container {
      flex-direction: column;
    }
    .form-header {
      margin: 20px 0;
    }
  }
  
`;
