import React from "react";
import moment from "moment";
import styled from "styled-components";
import { HorizontaLine } from "../common/styleComponent";
import { colorslotCondition } from "../common/styleComponent";
import SlotCondition from "../common/SlotCondition";
import ShowText from "../common/ShowText";

const Available = {
  label: "Available",
  boxSize: "25px",
  color: colorslotCondition.availableColor,
  fontSize: "12px",
  fontWight: 600,
};

const almostFull = {
  label: "Almost full",
  boxSize: "25px",
  color: colorslotCondition.fullColor,
  fontSize: "12px",
  fontWight: 600,
};
const waitList = {
  label: "Wait list",
  boxSize: "25px",
  color: colorslotCondition.waitColor,
  fontSize: "12px",
  fontWight: 600,
};

const BookingForCard = ({ data }) => {

 
  let endTime = new Date(data?.event?.endTime * 1000);
  let startTime = new Date(data?.event?.startTime * 1000);
  let price = data?.currencyType?.replace('(','')
  price = price?.replace(')','')+data?.pricePerTerm
 
  const DolphinsYears = {
    label: data?.eventName,
    boxSize: "28px",
    color:colorslotCondition[data?.indicatorColor],
    fontSize: "20px",
    fontWight: 700,
    fontColor: "#0B1E61",
   
  };


  return (
    <BookingCardStyle>
      <h1 className="for-booking-heading">You are booking for</h1>
      <SlotCondition filter={false} conditions={DolphinsYears} />
      <div className="key-value-container">
        <p className="key-heading">Venue:</p>
        <p className="value-heading">
          <ShowText text={data?.venue?.venueName} />
        </p>
      </div>
      <div className="key-value-container">
        <p className="key-heading">Coach:</p>
        <p className="value-heading">
          <ShowText  text={data?.couchName} />
        </p>
      </div>
      {data?.pricePerTerm && (
        <div className="key-value-container">
          <p className="key-heading">Price:</p>
          <p className="value-heading">
          {price}
          </p>
        </div>
      )}
      <p className="time-range">
      {moment(startTime).format('hh:mm A')} - {moment(endTime).format('hh:mm A')}
      </p>
      {/* <Days data={data} /> */}
      {/* {data?.recurrance === "2" && <p className="recurring-para">Recurring</p>}
      {data?.recurrance === "1" && <p className="recurring-para">One off</p>} */}
      <HorizontaLine color="#C4C4C4" height="1px" />
      <div className="check-box-container">
        <SlotCondition filter={false}  conditions={Available} />
        <SlotCondition filter={false}  conditions={almostFull} />
        <SlotCondition filter={false}  conditions={waitList} />
      </div>
    </BookingCardStyle>
  );
};

export default BookingForCard;

const BookingCardStyle = styled.div`
  padding: 48px;
  max-width: 480px;
  height: fit-content;
  border-radius: 16px;
  background: #f7f6f4;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .for-booking-heading {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 700;
    font-family: Montserrat;
    line-height: 32px;
  }
  .key-value-container {
    display: flex;
    gap: 5px;
    justify-content: start;
    align-items: baseline;
  }
  .key-heading {
    font-weight: 700;
    font-family: Montserrat;
    font-size: 20px;
    line-height: 32px;
  }
  .value-heading {
    font-weight: 400;
    font-family: Montserrat;
    font-size: 16px;
    line-height: 32px;
    color: #0b1e61;
  }
  .time-range {
    margin: 16px 0;
    font-weight: 700;
    font-family: Montserrat;
    font-size: 20px;
    line-height: 28px;
    color: #444a69;
  }

  .recurring-para {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #0b1e61;
    border-radius: 8px;
    padding: 4px 16px;
    background: #ffffff;
    width: 100px;
  }

  .check-box-container {
    display: flex;
    justify-content: start;
    gap: 16px;
    margin-top: 30px;
  }

  @media (max-width: 900px) {
    padding: 20px;
    width:100%;
    .for-booking-heading{
      margin-bottom: unset;
    }
    .key-heading{
      font-size: 16px;
    }
    .value-heading{
      font-size: 14px;
    }
    .time-range{
     font-size: 16px;
    }
    .check-box-container{
      flex-direction: column;
    margin-top: unset;
    fontSize:20px;
    }
  }
`;
