import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dimond_alt } from "../utils/imageSvg";
import ButtonLoading from "../components/ButtonLoader";
import { ClosebtnX } from "../common/styleComponent";
import crypto from "crypto-js";

const VerifyOtpModal = ({ OtpHashed, closeModal, setVerifyEmail }) => {
  const [otpValue, setOptValue] = useState(null);
  const [errorshow, setErrorShow] = useState(false);
  const [checkOtp, setCheckOtp] = useState(false);
  const [color, setColor] = useState("black");
  const secretKey = process.env.REACT_APP_SECRET_KEY;

  async function verifyOtp() {
    if (checkOtp === otpValue) {
      setErrorShow(false);
      setVerifyEmail(true);
      closeModal(false);
    } else {
      setOptValue("");
      setErrorShow(true);
    }
  }

  const handleonChange = (value) => {
    if (value) {
      value = value.replace(/[^0-9]/g, "");
    }
    setOptValue(value);
    if (checkOtp === value) {
      setColor("green");
    } else if (value?.length === 6) {
      setColor("red");
    } else {
      setColor("black");
    }
  };

  useEffect(() => {
    const decryptToken = crypto.AES.decrypt(`${OtpHashed}`, secretKey).toString(
      crypto.enc.Utf8
    );
    setCheckOtp(decryptToken);
  }, []);

  return (
    <VerifyStyle color={color}>
      <ClosebtnX onClick={() => closeModal(false)}>x</ClosebtnX>
      <div className="modal-container">
        <div className="booking-complete-logo">
          <div className="complete-img">
            <img src={Dimond_alt} alt="" />
          </div>
          <p className="complete-para-email">Verification email</p>
        </div>
        <p className="para-text">
          You should have received a verification code to the email you entered.
          Please enter this code below to verify your email address
        </p>
        <input
          className="verify-input"
          value={otpValue}
          type="text"
          maxLength="6"
          placeholder="Enter your OTP!"
          onChange={(e) => handleonChange(e.target.value)}
        />
        <p className="error-invalid">
          {errorshow && "Incorrect OTP, Please try again!"}
        </p>
        <div className="btn-confrim">
          <ButtonLoading
            handleClick={verifyOtp}
            height="48px"
            width="140px"
            text="Verify"
          />
        </div>
      </div>
    </VerifyStyle>
  );
};

export default VerifyOtpModal;

const VerifyStyle = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  /* background-color: rgb(51, 55, 78); */
  z-index: 9999999;
  width: 100%;
  height: 100vh;

  .verify-input {
    color: ${({ color }) => color || "black"};
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #2e3038;
    padding: 8px 16px;
    height: ${(props) => props.height || "45px"};
    outline: none;
    margin: auto;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  p.error-invalid {
    color: red;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 5px;
  }
  .para-text {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: #192d5f;
    margin: 10px 0;
  }

  .modal-container {
    width: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #f7f6f4;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 32px;
    padding: 20px;
    animation-name: set-animation;
    animation-duration: 0.7s;
    @media (max-width: 450px) {
      width:85%;
      margin:auto;
    }
    @media (min-width: 400px) {
      @keyframes set-animation {
        from {
          top: 0;
          opacity: 0;
        }
        to {
          opacity: 1;
          visibility: visible;
          top: ${(props) => props.top || "50%"};
        }
      }
    }
  }

  .booking-complete-logo {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .complete-img {
    padding: 8px;
    background: white;
    border: 2px solid #192d5f;
    width: fit-content;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
  }
  .complete-para-email {
    color: #333333;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 28px;
    font-size: 24px;
  }
  .btn-confrim {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
`;
