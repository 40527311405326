import {  getApi, postApi } from "./APImethods";

const selectclass = "web/getVenues?";
export const getVenues = (payload) => {
  return getApi(selectclass+payload);
};

const getClubDetails = "/web/getClubDetailsAtWeb";
export const getClubDetailsAtWeb = (payload) => {
  return getApi(`${getClubDetails}?${payload}`);
};

const clubevent = "web/getClubEventTypeListing?";
export const getclubevent = (payload) => {
  return getApi(clubevent+payload);
};

const getfirstListing = "/web/clubEventsListing?";
export const getfirstListingApi = (payload) => {
  return getApi(getfirstListing + payload);
};

const getListing = "/web/clubEventsListingAndFilter?";
export const getListingApi = (payload) => {
  return getApi(getListing + payload);
};

const bookkingpostMyself = "/web/createBookingForMyself";
export const postBookingMyselfApi = (payload) => {
  return postApi(bookkingpostMyself, payload);
};

const bookkingpostChild = "/web/createBookingForChild";
export const postBookingApiChild = (payload) => {
  return postApi(bookkingpostChild, payload);
};
const verifyEmail = "/web/verifyOtp?";
export const postVerifyOtpApi = (payload) => {
  return postApi(verifyEmail, payload);
};

const listEventsMonthWise = "/web/listTotalEventsMonthWiseForWeb?";
export const getListEventsMonthWise = (payload) => {
  return getApi(listEventsMonthWise + payload)
}
const retriveBooking = "/web/retrievePaymentDetailForLead?paymentIntentId=";
export const getRetriveBooking = (payload) => {
  return getApi(`${retriveBooking}${payload}`)
}

const createPaymentIntent="web/createPaymentIntentForLead";
export const createPaymentIntentApi = (payload) => {
  return postApi(createPaymentIntent, payload);
};
