import styled from "styled-components";
import vector from "../assets/Vector (13).svg";
import Searchdropdown from "./Searchdropdown";
import ButtonLoader from "./ButtonLoader";
import { useSelector } from "react-redux";
import { colorslotCondition } from "../common/styleComponent";
import SlotCondition from "../common/SlotCondition";
import { useEffect, useState } from "react";

const Available = {
  label: "Available",
  boxSize: "30px",
  color: colorslotCondition.availableColor,
  fontSize: "16px",
  fontWight: 600,
  boxshadow: "3px 4px 5px 0px #80808075",
};

const almostFull = {
  label: "Almost full",
  boxSize: "30px",
  color: colorslotCondition.fullColor,
  fontSize: "16px",
  fontWight: 600,
  boxshadow: "3px 4px 5px 0px #80808075",
};
const waitList = {
  label: "Full/Wait list",
  boxSize: "30px",
  color: colorslotCondition.waitColor,
  fontSize: "16px",
  fontWight: 600,
  boxshadow: "3px 4px 5px 0px #80808075",
};

function Filtter({
  defaultValue,
  loading,
  data,
  width,
  searchBtn,
  handleSubmit,
  btnClickable,
  allowClear,
  handleFilter,
}) {
  const filterValue = useSelector((store) => store?.filterValue?.data) || {};
  const classOption = filterValue?.class || null;
  const datavanue = filterValue?.location || null;
  const dataclubevent = filterValue?.classType || null;



  const [defaultVal, setDefaultVal] = useState(null);

  const [selectClass, setSelectClasss] = useState(
    data?.defaultValue.class?.value || null
  );
  const [selectClassType, setSelectClassType] = useState(
    data?.defaultValue?.classType?.value || null
  );
  const [setectLocation, setSetectLocation] = useState(
    data?.defaultValue?.location?.value || null
  );

  const [colorFilter,setColorfilter]=useState({
    Available:true,
    almostFull:true,
    waitList:true
  })

  const [selectFilter,setSelectFilter]=useState({})

  const handleSelected = (selectValue) => {
    const objSelecter = {};
    if (selectValue?.type === "class") {
      setSelectClasss(selectValue?.value);
      if (selectValue?.value) objSelecter["eventType"] = selectValue?.value;
    } else if (selectClass) objSelecter["eventType"] = selectClass;

    if (selectValue?.type === "classType") {
      setSelectClassType(selectValue?.value);
      if (selectValue?.value)objSelecter["clubEventTypeId"] = selectValue?.value;
    } else if (selectClassType)
      objSelecter["clubEventTypeId"] = selectClassType;

    if (selectValue?.type === "location") {
      setSetectLocation(selectValue?.value);
      if (selectValue?.value) objSelecter["venueId"] = selectValue?.value;
    } else if (setectLocation) objSelecter["venueId"] = setectLocation;
    setSelectFilter(objSelecter)
    setDefaultVal((pev) => {
      return {
        ...pev,
        [selectValue?.type]: selectValue,
      };
    });
    const keyset = {[selectValue?.type]: selectValue,}
    const defaultSelect = {...defaultVal ,...keyset}
    handleFilter(objSelecter,colorFilter,defaultSelect,);
  };
  
  const handlecolor=(value)=>{
    const objf = {...colorFilter};
    const onlyColorFilter = true;
    if(value.label===Available.label){
      setColorfilter((pev) => {
        return {
          ...pev,
          Available: (!colorFilter?.Available),
        };
      });
      objf.Available=(!colorFilter?.Available)
  
    }else if(value.label===almostFull.label){
       setColorfilter((pev) => {
        return {
          ...pev,
          almostFull: !colorFilter?.almostFull,
        };
      });
      objf.almostFull=(!colorFilter?.almostFull)


    }else if(value.label===waitList.label){
      setColorfilter((pev) => {
        return {
          ...pev,
          waitList: (!colorFilter?.waitList),
        };
      });
      objf.waitList=(!colorFilter?.waitList)

    }
    handleFilter(selectFilter,{value,...objf},defaultVal,onlyColorFilter)
  }
  useEffect(() => {
    if(!defaultVal?.class){
      setDefaultVal({...defaultValue,class:{value: 1, label: 'One off', type: 'class'}});
    }
  },[])


  return (
    <>
      <Filtterwrapper colorFilter={colorFilter} isfullwidth={width || false} btnClickable={btnClickable}>
        <div className="filtter-wrap">
          <div className="all-search-drop-down">
            <img className="vectorimg" src={vector} alt="" />
            <Searchdropdown
              width={"100%"}
              data={classOption}
              // allowClear={allowClear}
              placeholder={"Select Event Type"}
              handleclick={(e, label) =>
                handleSelected({ value: e, label: label?.label, type: "class" })
              }
              value={data?.class}
              defaultValue={defaultValue?.class || null}
            />
            <Searchdropdown
              width={"100%"}
              data={dataclubevent}
              allowClear={allowClear}
              placeholder={"Select Event"}
              handleclick={(e, label) =>
                handleSelected({
                  value: e,
                  label: label?.label,
                  type: "classType",
                })
              }
              defaultValue={defaultValue?.classType || null}
            />
            <Searchdropdown
              width={"100%"}
              data={datavanue}
              allowClear={allowClear}
              defaultValue={defaultValue?.location || null}
              placeholder={"Select Location"}
              handleclick={(e, label) =>
                handleSelected({
                  value: e,
                  label: label?.label,
                  type: "location",
                })
              }
            />
          </div>
          {data && (
            <div className="box-wrap">
              <div className="box-available" onClick={() => handlecolor(Available)}>
                <SlotCondition conditions={Available} />
              </div>
              <div  className="box-almostFull" onClick={() => handlecolor(almostFull)}>
                <SlotCondition conditions={almostFull} />
              </div>
              <div  className="box-waitList" onClick={() => handlecolor(waitList)}>
                <SlotCondition conditions={waitList} />
              </div>
            </div>
          )}
        </div>

        {searchBtn && (
          <div className="search-botton-wrap">
            {loading ? (
              <button className="search-button">
                <ButtonLoader onlyLoading={true} />
              </button>
            ) : (
              <button
                className="search-button"
                type="serch"
                onClick={handleSubmit}
              >
                Search
              </button>
            )}
          </div>
        )}
      </Filtterwrapper>
    </>
  );
}

export default Filtter;

const Filtterwrapper = styled.div`
.ant-select-clear {
  color:red;
  :hover {
    color:red;
  }
}

  .filtter-wrap {
    width: 100%;
    border: 1px solid #2e3038;
    background: #ffffff;
    padding: 24px 32px 24px 32px;
    border-radius: 16px;
    display: flex;
    gap: 12px;
    border-style: none;
    outline: none;
    align-items: center;
    justify-content: space-between;
  }

  .vectorimg {
    height: 24px;
    width: 24px;
  }
  .box-wrap {
    gap: 16px;
    white-space: nowrap;
    display: flex;
  }
  .all-search-drop-down {
    width: ${({ isfullwidth }) => (isfullwidth ? "100%" : "728px")};
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
  }
  .box-available{
    opacity: ${({colorFilter})=>colorFilter?.Available?'1':"0.4"};
    cursor: pointer;
  }
  .box-waitList{
    opacity: ${({colorFilter})=>colorFilter?.waitList?'1':"0.4"};
    cursor: pointer;
  }
  .box-almostFull{
    opacity: ${({colorFilter})=>colorFilter?.almostFull?'1':"0.4"};
    cursor: pointer;
  }

  .search-button {
    width: 411px;
    height: 64px;
    padding: 12px 16px 12px 16px;
    gap: 8px;
    border-radius: 24px;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    border-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .search-botton-wrap {
    margin-top: 63px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: ${(props) => (props?.btnClickable ? "1" : "0.3")};
    pointer-events: ${(props) => (props?.btnClickable ? "1" : "none")};
  }

  @media (max-width: 850px) {
    .all-search-drop-down {
      flex-direction: column;
    }
    .vectorimg {
      display: none;
    }
  }

  @media (max-width: 1300px) {
    .filtter-wrap {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media (max-width: 850px) {
    .all-search-drop-down {
      width: 100%;
    }
    .filtter-wrap {
      padding:17px;
      align-items: flex-start;
    }
  }
  @media (max-width: 590px) {
    .search-botton-wrap {
      margin-top: 20px;
    }
  }
  @media (max-width: 490px) {
    .box-wrap {
      flex-direction: column;
    }
  }
`;
