import React, { useState } from "react";
import { Calendar, ConfigProvider } from "antd";
import styled from "styled-components";
import moment from "moment";
import leftvect from "../assets/leftvect.svg";
import rightvect from "../assets/rightvact.svg";

const CalenderDateShow = ({ handleselectDate, eventsOnDate }) => {
  const [selectedMonth, setSelectedMonth] = useState(moment());

  const getListData = (value) => {
    let listData;
    let n = 0;
    if (
      eventsOnDate?.map((dt) => {
        if (dt?.momentDate == value.date()) n = dt?.count;
      }) &&
      new Date(value)?.getMonth() === moment(selectedMonth).month()
    ) {
      listData = [
        {
          type: "warning",
          available: n >= 4 ? "4" : `${n}`,
        },
      ];
    }
    return listData || [];
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <div className="events">
        {listData?.map((item) => (
          <div className="dot-tick-box" key={item.content}>
            {item?.available === "4" && (
              <>
                <div className="acitve-number"></div>
                <div className="acitve-number"></div>
                <div className="acitve-number"></div>
                <div className="acitve-number"></div>
              </>
            )}
            {item?.available === "3" && (
              <>
                <div className="acitve-number"></div>
                <div className="acitve-number"></div>
                <div className="acitve-number"></div>
              </>
            )}
            {item?.available === "2" && (
              <>
                <div className="acitve-number"></div>
                <div className="acitve-number"></div>
              </>
            )}
            {item?.available === "1" && <div className="acitve-number"></div>}
          </div>
        ))}
      </div>
    );
  };

  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    return info.originNode;
  };

  const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];

  const dayHeaderRender = ({ value, type, onChange }) => {
    return (
      <CalendarHeaderstyle>
        <div className="calender-month-wrap">
          <div
            className="wraper-tow-side-vect"
            onClick={() => {
              // const getYears = moment(selectedMonth).format("YYYY");
              const getYears = moment(selectedMonth).subtract(1,"Y");
              const mo =  moment(selectedMonth).format("MM");
              const previousMonth = moment(selectedMonth).subtract(1, "M");
              const firstDate = moment(selectedMonth).date(1, 'd')
              let now = value.clone().month(previousMonth.month());
              // const setyear = moment(selectedMonth).year(getYears,'y')
              now = now.clone().date(firstDate.date());
              if(mo=="01"){
                now = now.clone().year(getYears.year()); 
              }
              setSelectedMonth(previousMonth);
              onChange(now);
              handleselectDate(now)
            }}
          >
            <img className="vecter" src={leftvect} alt="" />
          </div>
          <p className="month-year">
            {" "}
            {moment(selectedMonth).format("MMM YYYY")}
          </p>
          <div
            className="wraper-tow-side-vect"
            onClick={() => {
              // const getYears = moment(selectedMonth).format("YYYY");
              const getYears = moment(selectedMonth).add(1,"Y");
              const mo =  moment(selectedMonth).format("MM");
              const nextMonth = moment(selectedMonth).add(1, "M")
              const firstDate = moment(selectedMonth).date(1, 'd')
              // const setyear = moment(selectedMonth).year(getYears,'y')
              let now = value.clone().month(nextMonth.month());
              now = now.clone().date(firstDate.date()); 
              if(mo=="12"){
                now = now.clone().year(getYears.year()); 
              }
              setSelectedMonth(nextMonth);
              onChange(now);
              handleselectDate(now)
              
            }}
          >
            <img className="vecter" src={rightvect} alt="" />
          </div>
        </div>

        <div className="custom-header">
          {daysOfWeek.map((day, index) => (
            <div key={index}>{day}</div>
          ))}
        </div>
      </CalendarHeaderstyle>
    );
  };

  return (
    <CalenderStyle>
      <ConfigProvider
        theme={{
          components: {
            Calendar: {
              fontWeightStrong: 700,
            },
          },
          token: {
            fontSize: 17,
            padding: 60,
            colorText: "#33374e",
            colorPrimary: "#d9dbe1",
            colorTextTertiary: "#33374e",
            colorTextHeading: "rgba(0, 0, 0, 0.25)",
            colorTextDisabled: "#d9dbe1",
            colorSplit: "transparent",
          },
        }}
      >
        <Calendar
          mode="month"
          fullscreen={false}
          style={{ width: "100%", height: "fit-content" }}
          cellRender={cellRender}
          headerRender={(value, type, onChange, onTypeChange) =>
            dayHeaderRender(value, type, onChange, onTypeChange)
          }
          onSelect={handleselectDate}
  
        />
      </ConfigProvider>
    </CalenderStyle>
  );
};
export default CalenderDateShow;

const CalendarHeaderstyle = styled.div`

 .calender-month-wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 25px;
  }
  .wraper-tow-side-vect{
    width: 40px;
    height: 40px;
    border-radius: 24px;
    background: #d9dbe1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vecter {
    width: 24px;
    height: 24px;
  }
  .month-year {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }
  .custom-header {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    color: #33374e;
}

.custom-header > div {
  width: calc(100% / 7);
  text-align: center;
} 

`

const CalenderStyle = styled.div`
  width: 400px;
  @media (max-width: 410px) {
      width: 360px;
    
  }
  .acitve-number {
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: #28a3a9;
  }
  .dot-tick-box {
    display: flex;
    width: fit-content;
    margin: auto;
    gap: 2px;
  }
  .ant-picker-content th {
    box-sizing: border-box;
    padding: 0;
    color: #33374e !important;
    font-size: 15px !important;
    font-weight: 700 !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
      .ant-picker-cell-disabled
    )
    .ant-picker-cell-inner,
  :where(.css-dev-only-do-not-override-3ewpte).ant-picker-calendar
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-disabled
    )
    .ant-picker-cell-inner,
  :where(.css-dev-only-do-not-override-3ewpte).ant-picker-calendar
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-disabled
    )
    .ant-picker-cell-inner {
    color: "#33374e" !important;
    background: #d9dbe1;
    border-radius: 100% !important;
    /* width: 20px!important;
   height: 20px!important; */
  }
  .ant-picker-cell-inner::before {
    color: "#33374e" !important;
    border-radius: 100% !important;
  }
  /* .ant-picker-cell-disabled::before {
    background-color: transparent !important;
  }
  .ant-picker-content td {
    color: #000 !important;
  } */
  .wraper-tow-side-vect{
    cursor: pointer
  }
`;
