import { memo, useState } from "react";
import { Formik } from "formik";
import InputFields from "./InputField";
import {
  bookingvalidationWithchild,
  bookingvalidationWithchildemail,
} from "./formValidation";
import VerifyOtpModal from "../modal/VerifyOtpModal";
import { postVerifyOtpApi } from "../services/Collection";
import { toast } from "react-toastify";

const FormikFieldChild = [
  {
    name: "childFirstName",
    type: "text",
    placeholder: "First Name",
    maxlength: "30",
  },
  {
    name: "childLastName",
    type: "text",
    placeholder: "Last Name",
  },
];
const childemail = {
  name: "childEmail",
  type: "email",
  placeholder: "Email",
};
const initialValue2 = {};
for (let i = 0; i < FormikFieldChild?.length; i++) {
  initialValue2[FormikFieldChild[i]?.name] = "";
}

const ChildForm = ({
  setSelectChildEmail,
  selectChildEmail,
  submitChild,
  formRef,
  data,
  verifyEmailChild,
  setVerifyEmailChild,
}) => {
  const [verifyModal, setVerifyModal] = useState(false);
  const [OtpHashed, setOptHashed] = useState(false);
  const [verifyEmailLoading, setVerifyEmailLoading] = useState(false);

  let validations = bookingvalidationWithchild;
  let initialValue = {
    ...initialValue2,
  };
  if (selectChildEmail === "yes") {
    initialValue = {
      ...initialValue2,
      childEmail: null,
    };
    validations = bookingvalidationWithchildemail;
  }

  async function emailOnchange(email) {
    let verifyObj = {
      apiType: "email",
      email: email,
      clubId: data?.clubId,
      eventId: data?.eventId,
    };
    if (data?.clubId) {
      setVerifyEmailLoading(true);
      const res = await postVerifyOtpApi(verifyObj);
      if (res?.status === 200) {
        setVerifyModal(true);
        setOptHashed(res?.data?.hashed);
      } else {
        toast.error(
          res?.response?.data?.message || res?.message || "Something went wrong"
        );
      }
    } else {
      toast.error("Club Id NOT FOUND!");
    }
    setVerifyEmailLoading(false);
  }
  return (
    <>
      {verifyModal && (
        <VerifyOtpModal
          OtpHashed={OtpHashed}
          setVerifyEmail={setVerifyEmailChild}
          closeModal={setVerifyModal}
        />
      )}
      <Formik
        innerRef={formRef}
        validationSchema={validations}
        initialValues={initialValue}
        onSubmit={submitChild}
      >
        {({ errors, touched, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="form-container2">
              <p className="form-header">Child details</p>
              {FormikFieldChild?.map((fieldValue, index) => {
                return (
                  <InputFields
                    key={fieldValue?.name}
                    field={fieldValue}
                    errors={errors}
                    touched={touched}
                    onChange={emailOnchange}
                    verifyEmail={verifyEmailChild}
                    verifyEmailLoading={verifyEmailLoading}
                  />
                );
              })}
              {/* <DatePickerComponent
                childDoberror={childDoberror}
                handleSelectData={handleSelectData}
              /> */}

              <div className="radio-btn-container">
                <div className="radio-box">
                  <input
                    type="radio"
                    name="childemail"
                    value="yes"
                    className="radio-btn"
                    defaultChecked={true}
                    onChange={(e) => setSelectChildEmail(e.target.value)}
                  />
                  <p className="radio-label">Has Email</p>
                </div>
                <div className="radio-box">
                  <input
                    type="radio"
                    name="childemail"
                    value="no"
                    className="radio-btn"
                    onChange={(e) => setSelectChildEmail(e.target.value)}
                  />
                  <p className="radio-label">Doesn’t have email</p>
                </div>
              </div>
              {selectChildEmail === "yes" && (
                <InputFields
                  field={childemail}
                  errors={errors}
                  touched={touched}
                  onChange={emailOnchange}
                  verifyEmail={verifyEmailChild}
                  verifyEmailLoading={verifyEmailLoading}
                />
              )}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default memo(ChildForm);
