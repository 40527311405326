import patterimg from "../assets/Pattern.svg";
import styled from "styled-components";
import Filtter from "../components/Filtter";
import outcoachlogo from "../assets/Outcoach logo 1.svg";
import Bookcompo from "../components/Bookcompo";
import navarrowright from "../assets/nav-arrow-right.svg";
import { memo, useEffect, useState } from "react";
import { HorizontaLine } from "../common/styleComponent";
import { getListingApi, getfirstListingApi } from "../services/Collection";
import AppLoader from "../Loader/AppLoader";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../components/ButtonLoader";
import { toast } from "react-toastify";
import { filterOption } from "../store/filterValueSlice";
import { useDispatch, useSelector } from "react-redux";
import NotDataFound from "../utils/NotDataFound";

import {
  handlegetclubevent,
  handlegetvanues,
  manipulateData,
} from "../utils/constantPath";
import moment from "moment";

function Landing() {
  const dispatch = useDispatch();
  const [listingData, setListingData] = useState([]);
  const [btnClickable, setBtnclickable] = useState(false);
  const [pageNumber, setPageNumber] = useState(2);
  const [totalCount, setTotalCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [btnloading, setBtnloading] = useState(false);
  const [selecterid, setSelectid] = useState(null);
  const [defaultValue, setDefaultVal] = useState(null);

  const clubId = useSelector((store) => store?.login?.data?.id);

  const navigate = useNavigate();

  const handleFilter = (selectValue, colorFilter, defaultVal) => {
    if (Object.keys(selectValue).length >= 1) {
      setBtnclickable(true);
      setSelectid(selectValue);
    } else {
      setBtnclickable(false);
    }
    setDefaultVal(defaultVal);
  };

  const createGroupLastestEvent = (payload) => {

    let updatedArray = [];
    let tempIds = [];
    let date = "";

    for (let i = 0; i < payload.length; i++) {
      const element = payload[i];
      if (tempIds?.includes(element?.event?.id)) {
        if (moment(date) > moment(element?.date)) {
          updatedArray[updatedArray?.length - 1] = element;
          date = element?.date;
        }
      } else {
        tempIds.push(element?.event?.id);
        updatedArray.push(element);
        date = element?.date;
      }
    }

    return updatedArray;
  };

  const getfirstListing = async () => {
    setLoading(true);
    const params = new URLSearchParams();
    params.append("clubId", clubId);
    // params.append("pageNumber", 1);
    if (clubId) {
      const res = await getfirstListingApi(params);
      if (res?.status == 200) {
        const updatedGroup =  createGroupLastestEvent(res?.data);
        const result = await manipulateData(updatedGroup);
        setTotalCount(res?.extraData?.count);
        setListingData(result);
      } else {
        toast.error(
          res?.response?.data?.message || res?.message || "Something went wrong"
        );
      }
      setLoading(false);
    } else {
      toast.error("Club Id NOT FOUND!");
    }
  };

  // const paginationListing = async () => {
  //   const params = new URLSearchParams();
  //   params.append("clubId", clubId);
  //   params.append("pageNumber", pageNumber);
  //   if (clubId) {
  //     setVeiwMoreLoader(true);
  //     const res = await getfirstListingApi(params);
  //     if (res?.status == 200) {
  //       const result = await manipulateData(res?.data);
  //       setListingData((previous) => [...previous, ...result]);
  //       setPageNumber(pageNumber + 1);
  //     } else {
  //       toast.error(
  //         res?.response?.data?.message || res?.message || "Something went wrong"
  //       );
  //     }
  //     setVeiwMoreLoader(false);
  //   } else {
  //     toast.error("Club Id NOT FOUND!");
  //   }
  // };

  const getfilterListing = async () => {
    const params = new URLSearchParams();
    params.append("clubId", clubId);
    params.append("pageNumber", 1);
    if (selecterid?.hasOwnProperty("eventType")) {
      params.append("eventType", selecterid?.eventType);
    }
    if (selecterid?.hasOwnProperty("clubEventTypeId")) {
      params.append("clubEventTypeId", selecterid?.clubEventTypeId);
    }
    if (selecterid?.hasOwnProperty("venueId")) {
      params.append("venueId", selecterid?.venueId);
    }
    if (clubId) {
      setBtnloading(true);
      const res = await getListingApi(params);
      if (res?.status == 200) {
        const updatedGroup =  createGroupLastestEvent(res?.data);
        const result = await manipulateData(updatedGroup);
        setBtnloading(false);
        navigate("/searchMelbourne", {
          state: {
            selectType: selecterid,
            listing: result,
            defaultValue: defaultValue,
            totalCount: res?.extraData?.count,
          },
        });
      } else {
        toast.error(
          res?.response?.data?.message || res?.message || "Something went wrong"
        );
      }
    } else {
      toast.error("Club Id NOT FOUND!");
    }
  };

  async function getFilterValue() {
    const classType = await handlegetclubevent(clubId);
    const location = await handlegetvanues(clubId);
    dispatch(filterOption({ classType: classType, location: location }));
  }

  useEffect(() => {
    if (clubId) {
      getfirstListing();
      getFilterValue();
    } else {
      toast.error("Club Id NOT FOUND!");
    }
  }, []);

  return (
    <div>
      <Nextclasswrapper>
        <div className="main-wrap-next-class">
          <p className="book-next-hadding">Let’s get started!</p>
          <Filtter
            handleFilter={handleFilter}
            handleSubmit={getfilterListing}
            searchBtn={true}
            width={true}
            loading={btnloading}
            btnClickable={btnClickable}
            allowClear={true}
          />
        </div>
      </Nextclasswrapper>

      <Upcommingclass>
        <div className="main-wrap-hadding">
          <div className="wrapper-hadding">
            <p className="upcoming-hadding">Upcoming Events</p>
            <div className="right-side-hadding-wrap">
              <p className="powered-hadding">Powered by</p>
              <img src={outcoachlogo} alt="" />
            </div>
          </div>
          <HorizontaLine height="1px" margin="0 0 24px 0" />
          {loading ? (
            <AppLoader />
          ) : listingData?.length === 0 ? (
            <NotDataFound />
          ) : (
            <>
              <BookingBoxWrapper>
                {listingData?.map((el, index) => (
                  <Bookcompo data={el} key={index} />
                ))}
              </BookingBoxWrapper>
              {/* <Viewmorewrapper>
                {!(totalCount === listingData?.length) && (
                  <div className="main-wrap-viewmore-button">
                    {viewMoreLoader ? (
                      <div className="view-more-wrap">
                        <ButtonLoader onlyLoading={true} />
                      </div>
                    ) : (
                      <div
                        className="view-more-wrap"
                        onClick={paginationListing}
                        role="button"
                      >
                        <button className="view-more-hadding">View more</button>
                        <img
                          className="nav-arrow-right"
                          src={navarrowright}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                )}
              </Viewmorewrapper> */}
            </>
          )}
        </div>
      </Upcommingclass>
    </div>
  );
}
export default memo(Landing);

export const BookingBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: 1110px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 785px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

// const Viewmorewrapper = styled.div`
//   .view-more-wrap {
//     width: 411px;
//     height: 64px;
//     border-radius: 24px;
//     border-style: none;
//     outline: none;
//     background: #ffffff;
//     border: 1px solid #33374e;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     gap: 8px;
//     cursor: pointer;
//   }
//   .view-more-hadding {
//     font-family: Montserrat;
//     font-size: 20px;
//     font-weight: 700;
//     background: transparent;
//     border-style: none;
//     cursor: pointer;
//   }
//   .nav-arrow-right {
//     width: 24px;
//     height: 24px;
//   }
//   .main-wrap-viewmore-button {
//     width: 100%;
//     height: 64px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-top: 32px;
//   }

//   @media (max-width: 620px) {
//     .view-more-wrap {
//       width: 380px;
//     }
//   }

//   @media (max-width: 555px) {
//     .view-more-wrap {
//       width: 300px;
//       height: 60px;
//     }
//   }

//   @media (max-width: 450px) {
//     .view-more-wrap {
//       width: 200px;
//       height: 55px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }
//   }
// `;

const Nextclasswrapper = styled.div`
  .main-wrap-next-class {
    width: 100%;
    background: url(${patterimg});
    padding: 24px 32px 24px 32px;
    margin-bottom: 40px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .book-next-hadding {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font: Montserrat;
    margin-bottom: 48px;
  }

  @media (max-width: 590px) {
    .book-next-hadding {
      font-size: 35px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 450px) {
    .book-next-hadding {
      font-size: 30px;
    }
  }

  @media (max-width: 390px) {
    .book-next-hadding {
      font-size: 23px;
    }
  }
`;

const Upcommingclass = styled.div`
  .upcoming-hadding {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .powered-hadding {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
  }

  .wrapper-hadding {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .right-side-hadding-wrap {
    display: flex;
    align-items: center;
    gap: 9px;
  }
  .main-wrap-hadding {
    width: calc(100% - 64px);
    margin: auto;
    padding: 25.5px 32px;
    border-radius: 16px 16px 0px 0px;
    background: #ffffff;
    box-shadow: 1px 1px 8px 0px #aaaaaa80;
  }

  @media (max-width: 600px) {
    .right-side-hadding-wrap {
      display: none;
    }
  }

  @media (max-width: 600px) {
    .upcoming-hadding {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }
  }

  @media (max-width: 480px) {
    .main-wrap-hadding {
      width: calc(100% - 30px);
      margin: auto;
      padding: 25.5px 12px;
    }
  }
`;
